



















































































































































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import TourMemberAvatars from '@/components/Mobile/TourMemberAvatars.vue';
import { formatLongDate } from '@/utils/date_time';
import Attendance from '@/dtos/attendance';
import TourLikeButton from '@/components/Mobile/TourLikeButton.vue';
import ExpandableText from '@/components/ExpandableText.vue';
import ModalQRCode from '@/components/Mobile/ModalQRCode.vue';
import QRButton from '@/components/Mobile/QRButton.vue';
import Place from '@/dtos/place';
import { links } from '@/utils/link-detector';
import QRLinks from '@/components/Mobile/QRLinks.vue';
import { formatString } from '@/utils/string-formatter';
import Banderole from '@/views/Mobile/TourDetail/Banderole.vue';

interface Message {
  text: string;
  date?: string;
}

export default Vue.extend({
  components: {
    Scaffold,
    TourMemberAvatars,
    TourLikeButton,
    ExpandableText,
    ModalQRCode,
    QRButton,
    QRLinks,
    Banderole,
  },
  data() {
    return {
      qrText: null as string | null,
    };
  },
  computed: {
    ...mapGetters({
      tour: 'tour/getCurrentTour',
      canMemberJoin: 'tour/canMemberJoin',
      user: 'auth/getUser',
      messages: 'chat/getMessages',
    }),
    recentMessages(): Message[] {
      const recents = this.messages.slice(-2);
      const count = recents.length;
      if (!count) return [{ text: 'Starte jetzt den Tourchat' }];
      return recents.map((m: any) => {
        return { text: m.content, date: m.timestamp };
      });
    },
    tourMemberStatus(): Attendance {
      return this.canMemberJoin(this.user.data.uid);
    },
    isOrganizer(): boolean {
      return this.tourMemberStatus === Attendance.Organizer;
    },
    hasJoinedTour(): boolean {
      return this.tourMemberStatus === Attendance.Cancel;
    },
    canJoinTour(): boolean {
      return this.tourMemberStatus === Attendance.Possible;
    },
    cannotJoinTour(): boolean {
      return this.tourMemberStatus === Attendance.NotPossible;
    },
    isPastTour(): boolean {
      return this.tour.startDateTime < new Date();
    },
  },
  async mounted() {
    this.$store.dispatch('toggleLoading', true);
    await this.$store.dispatch(
      'tour/bindCurrentTour',
      this.$route.params.tourId
    );
    this.$store.dispatch('chat/bindChatMessages', this.$route.params.tourId);
    this.$store.dispatch('toggleLoading', false);
  },
  methods: {
    descriptionLinks({
      include = [],
      exclude = [],
    }: {
      include: string[];
      exclude: string[];
    }) {
      return links(this.tour.description, include, exclude);
    },
    formatDate(input: any): string {
      return formatLongDate(input);
    },
    openMaps(place: Place) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${place.latitude},${place.longitude}`
      );
    },
    openQR(text: string) {
      this.qrText = text;
    },
    joinTour() {
      this.updateAttendance(Attendance.Possible);
    },
    cancelTour() {
      this.updateAttendance(Attendance.Cancel);
    },
    updateAttendance(status: Attendance) {
      this.$store.dispatch('tour/updateAttendance', {
        tour: this.tour,
        status,
      });
    },
    duplicateTour() {
      this.$router.push({
        name: 'Mobile Duplicate Tour',
        params: { tourId: this.$route.params.tourId },
      });
    },
    editTour() {
      this.$router.push({
        name: 'Mobile Edit Tour',
        params: { tourId: this.$route.params.tourId },
      });
    },
    removeTour() {
      if (confirm('Möchtest du diese Tour wirklich löschen?')) {
        this.$store
          .dispatch('tour/removeTour', this.tour)
          .then(() => this.$router.push({ name: 'Mobile Discover' }));
      }
    },
    shareTour(): void {
      const t = this.tour;
      if (!t) return;

      const title = t.title;
      const message = formatString(
        this.$t('tourSharingText') as string,
        t.title,
        formatLongDate(t.startDateTime),
        t.location.description
      );
      const url = ['https://', window.location.hostname, '/tours/', t.id].join(
        ''
      );
      navigator.share({ title: title, text: message, url: url });
    },
  },
});

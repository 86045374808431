



































import Picture from '@/dtos/picture';
import Vue from 'vue';
import { mapGetters } from 'vuex';

class Cell {
  _picture: Picture;

  constructor(p: Picture) {
    this._picture = p;
  }

  get url() {
    return this._picture.thumbUrl || this._picture.url;
  }
}

export default Vue.extend({
  props: {
    tourId: {
      type: String,
      required: true,
    },
    showUploadInvite: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      pictures: 'tourpictures/pictures',
      canUpload: 'tourpictures/canUpload',
    }),
    cells(): Cell[] {
      return this.pictures.map((p: Picture) => new Cell(p)).slice(0, 10);
    },
  },
  mounted() {
    const dispatch = this.$store.dispatch;
    dispatch('tourpictures/bindPictures', this.tourId);
  },
});
